
                            $is-app_membership-enabled: true;
                            $loyalty-register-uniquecode-font: bold #{rem-calc(14)} / 1.5 $font-primary !default;
$loyalty-register-uniquecode-font--xlarge: 300 #{rem-calc(11)} / 1.5 $font-primary !default;
$loyalty-register-uniquecode-justify-content--xlarge: flex-end !default;
$loyalty-register-uniquecode-margin--xlarge: rem-calc(0 0 0 25) !default;
$loyalty-register-uniquecode-padding--xlarge: rem-calc(0 0 0 25) !default;

$loyalty-register-uniquecode-link-color: color(highlight) !default;
$loyalty-register-uniquecode-link-color--xlarge: $loyalty-register-uniquecode-link-color !default;
$loyalty-register-uniquecode-link-text-transform: uppercase !default;
$loyalty-register-uniquecode-link-text-transform--xlarge: none !default;
$loyalty-register-uniquecode-link-text-decoration: none !default;
$loyalty-register-uniquecode-link-hover-color: color(primary-active) !default;
$loyalty-register-uniquecode-link-icon: star !default;
$loyalty-register-uniquecode-link-icon-color: color(highlight) !default;
$loyalty-register-uniquecode-link-icon-margin: rem-calc(0 8 4 0) !default;
$loyalty-register-uniquecode-link-icon-margin--xlarge: rem-calc(-2 8 0 0) !default;
$loyalty-register-uniquecode-link-icon-size: rem-calc(15) !default;
$loyalty-register-uniquecode-link-icon-usesvg: true !default;

@mixin lora-components-loyalty-header-entrance {
    .c-loyalty-register-uniquecode {
        font: $loyalty-register-uniquecode-font;

        @include breakpoint(xlarge) {
            font: $loyalty-register-uniquecode-font--xlarge;
            display: flex;
            justify-content: $loyalty-register-uniquecode-justify-content--xlarge;
            margin: $loyalty-register-uniquecode-margin--xlarge;
            padding: $loyalty-register-uniquecode-padding--xlarge;
        }
    }

    .c-loyalty-register-uniquecode__link {
        color: $loyalty-register-uniquecode-link-color;
        text-transform: $loyalty-register-uniquecode-link-text-transform;
        text-decoration: $loyalty-register-uniquecode-link-text-decoration;

        @include breakpoint(xlarge) {
            text-transform: $loyalty-register-uniquecode-link-text-transform--xlarge;
            color: $loyalty-register-uniquecode-link-color--xlarge;
            position: relative;
        }

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: $loyalty-register-uniquecode-link-icon-size;
            width: $loyalty-register-uniquecode-link-icon-size;
            margin: $loyalty-register-uniquecode-link-icon-margin;

            @include svg-icon($loyalty-register-uniquecode-link-icon, $loyalty-register-uniquecode-link-icon-color, 100%, $global-left center, no-repeat, $usesvg: $loyalty-register-uniquecode-link-icon-usesvg);

            @include breakpoint(xlarge) {
                margin: $loyalty-register-uniquecode-link-icon-margin--xlarge;
            }
        }

        &:hover::before,
        &:focus::before {
            @include svg-icon($loyalty-register-uniquecode-link-icon, $loyalty-register-uniquecode-link-hover-color, 100%, $global-left center, no-repeat, $color-change: true, $usesvg: $loyalty-register-uniquecode-link-icon-usesvg);
        }

        &:hover,
        &:focus {
            color: $loyalty-register-uniquecode-link-hover-color;
        }
    }
}
                        